import React, { useRef, useEffect, useState } from "react";
import { motion, useScroll, useTransform } from "framer-motion";

const steps = [
  {
    number: 1,
    title: "Gib dein Google Ads-Konto ein",
    description:
      "Melde dich sicher über unsere Audit-Tool-Oberfläche in deinem Google Ads-Konto an.",
  },
  {
    number: 2,
    title: "Analysiere deine Daten",
    description:
      "Lass das Audit-Tool die Zahlen bearbeiten und deine Leistung bei Google Ads analysieren.",
  },
  {
    number: 3,
    title: "Erhalte maßgeschneiderte Empfehlungen",
    description:
      "Nach Abschluss der Analyse erhältst du personalisierte Empfehlungen, um deine Google Ads-Kampagnen zu optimieren und deine Ergebnisse zu maximieren.",
  },
];

const ScrollSteps = () => {
  const containerRef = useRef(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showBubble, setShowBubble] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 0, y: 0 });

  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start 70%", "start 30%"],
  });

  const lineProgress = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const unsubscribe = scrollYProgress.onChange((latest) => {
      const adjustedProgress = Math.max(0, Math.min(1, (latest - 0.3) / 0.4));
      const step = Math.floor(adjustedProgress * steps.length);
      setCurrentStep(step);
    });

    return () => unsubscribe();
  }, [scrollYProgress]);

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setBubblePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleMouseEnter = () => setShowBubble(true);
  const handleMouseLeave = () => setShowBubble(false);

  const DesktopLine = () => (
    <motion.div
      className="absolute top-4 h-[0.5px]"
      style={{
        width: lineProgress,
        background: `radial-gradient(210.77% 113.26% at 5.95% 7.14%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%),
                     linear-gradient(136deg, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0) 97.5%),
                     rgb(52, 178, 255)`,
      }}
    />
  );

  const MobileLine = () => (
    <motion.div
      className="absolute left-4 w-[0.5px]"
      style={{
        height: lineProgress,
        background: `radial-gradient(210.77% 113.26% at 5.95% 7.14%, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%),
                     linear-gradient(136deg, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0) 97.5%),
                     rgb(52, 178, 255)`,
      }}
    />
  );

  return (
    <div
      ref={containerRef}
      className="py-16 lg:py-28 relative text-white hover:cursor-none"
      id="works"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="px-4 lg:px-0">
        <h1 className="text-3xl lg:text-5xl lg:leading-normal font-light mb-12 text-flowPrimary lg:max-w-[1216px] lg:mx-auto">
          Drei einfache Schritte
          <br />
          <span className="text-flowWhite">zum Erfolg.</span>
        </h1>
        <div className="relative mb-20">
          {isMobile ? <MobileLine /> : <DesktopLine />}
          <div
            className={`flex lg:max-w-[1216px] lg:mx-auto ${
              isMobile ? "flex-col space-y-16" : "justify-between"
            }`}
          >
            {steps.map((step, index) => (
              <motion.div
                key={step.number}
                className={`${
                  isMobile ? "w-full pl-16 pt-0" : "w-1/3 pr-8"
                } relative z-20 pt-12`}
                initial={{ opacity: 0.3 }}
                animate={{ opacity: index <= currentStep ? 1 : 0.3 }}
              >
                <div
                  className={`
                    absolute ${
                      isMobile ? "left-0 top-0" : "left-0 top-0"
                    } mb-4 size-10 bg-[#1A1B1D] rounded-full flex items-center justify-center text-white
                    ${
                      index <= currentStep ? "border-[#34B2FF]" : "border-white"
                    } border
                  `}
                >
                  {step.number}
                </div>
                <div className="flex flex-col gap-4 pt-5">
                  <h2
                    className={`text-xl font-normal leading-snug ${
                      index === 2 ? "" : "lg:max-w-52"
                    }`}
                  >
                    {step.title}
                  </h2>
                  <p className="text-sm font-thin leading-snug tracking-[0.64px] text-flowWhite lg:max-w-[320px]">
                    {step.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
      {showBubble && (
        <div
          className="absolute pointer-events-none z-30"
          style={{
            left: `${bubblePosition.x}px`,
            top: `${bubblePosition.y}px`,
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="relative w-[220px] h-[220px]">
            <div className="absolute inset-0 rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40)] opacity-30 animate-pulsate"></div>
            <div className="absolute inset-[15px] rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40)] opacity-60 animate-pulsate"></div>
            <div className="absolute inset-[30px] rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40)] opacity-90 animate-pulsate"></div>
            <div className="absolute inset-[30px] bg-white rounded-full shadow-lg flex flex-row justify-center gap-2 items-center w-40 h-40">
              <p className="text-[#1A1B1D] text-sm leading-none">
                Jetzt probieren
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="4"
                viewBox="0 0 13 4"
                fill="none"
              >
                <path
                  d="M12.6768 2.17678C12.7744 2.07915 12.7744 1.92085 12.6768 1.82322L11.0858 0.232233C10.9882 0.134602 10.8299 0.134602 10.7322 0.232233C10.6346 0.329864 10.6346 0.488155 10.7322 0.585786L12.1464 2L10.7322 3.41421C10.6346 3.51184 10.6346 3.67014 10.7322 3.76777C10.8299 3.8654 10.9882 3.8654 11.0858 3.76777L12.6768 2.17678ZM0.5 2.25H12.5V1.75H0.5V2.25Z"
                  fill="#1A1B1D"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div className="relative flex items-center justify-center">
          <div className="rounded-full absolute border size-44 border-flowPrimary opacity-50 animate-pulsateNoOpacity"></div>
          <div className="rounded-full absolute border size-52 border-flowPrimary opacity-30 animate-pulsateNoOpacity"></div>
          <div className="rounded-full absolute border size-60 border-flowPrimary opacity-5 animate-pulsateNoOpacity"></div>
          <a
            href="#contact"
            className="flex flex-row justify-center items-center gap-2 size-40 bg-white rounded-full"
          >
            <span className="text-flow-14 text-[#1A1B1D]">Jetzt probieren</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="4"
              viewBox="0 0 13 4"
              fill="none"
            >
              <path
                d="M12.1768 2.17678C12.2744 2.07915 12.2744 1.92085 12.1768 1.82322L10.5858 0.232233C10.4882 0.134602 10.3299 0.134602 10.2322 0.232233C10.1346 0.329864 10.1346 0.488155 10.2322 0.585786L11.6464 2L10.2322 3.41421C10.1346 3.51184 10.1346 3.67014 10.2322 3.76777C10.3299 3.8654 10.4882 3.8654 10.5858 3.76777L12.1768 2.17678ZM0 2.25H12V1.75H0V2.25Z"
                fill="#1A1B1D"
              />
            </svg>
          </a>
        </div>
      )}
    </div>
  );
};

export default ScrollSteps;
