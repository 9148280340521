import React from "react";

const AccountOption = ({ account, depth, path }) => (
  <option
    value={account.customer_id}
    className={`${depth === 0 ? "font-bold" : ""}`}
    key={`${path}-${account.customer_id}`}
  >
    {"  ".repeat(depth)}
    {account.manager ? "📁 " : "📄 "}
    {account.descriptive_name} ({account.customer_id})
  </option>
);

const renderAccountOptions = (accounts, depth = 0, parentPath = "") => {
  return accounts
    .sort((a, b) => a.descriptive_name.localeCompare(b.descriptive_name))
    .map((account, index) => {
      const currentPath = `${parentPath}-${index}`;
      return (
        <React.Fragment key={currentPath}>
          <AccountOption account={account} depth={depth} path={currentPath} />
          {account.child_accounts &&
            account.child_accounts.length > 0 &&
            renderAccountOptions(
              account.child_accounts,
              depth + 1,
              currentPath
            )}
        </React.Fragment>
      );
    });
};

const AccountDropdown = ({ accounts, selectedAccount, onAccountChange }) => {
  return (
    <div className="relative">
      <select
        value={selectedAccount?.customer_id || ""}
        onChange={onAccountChange}
        className="block w-full bg-white border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline appearance-none text-gray-700"
      >
        <option value="">Select an account</option>
        {renderAccountOptions(accounts)}
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
        <svg
          className="fill-current h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
        >
          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
      </div>
    </div>
  );
};

export default AccountDropdown;
