import React, { useState } from "react";
import { motion } from "framer-motion";

// Import PNG files
import OMR from "../../knownFrom/OMR.png";
import Handelsblatt from "../../knownFrom/Handelsblatt.png";
import Welt from "../../knownFrom/WELT.png";
import Google from "../../knownFrom/Google.png";

const knownFrom = [
  {
    id: 1,
    name: "OMR",
    logo: OMR,
    link: "https://www.google.com",
  },
  {
    id: 2,
    name: "Handelblatt",
    logo: Handelsblatt,
    link: "https://www.google.com",
  },
  {
    id: 3,
    name: "Welt",
    logo: Welt,
    link: "https://www.google.com",
  },
  {
    id: 4,
    name: "Google",
    logo: Google,
    link: "https://www.google.com",
  },
];

const circleVariants = {
  initial: {
    backgroundColor: "transparent",
    border: "1px solid #666",
  },
  hover: {
    border: "1px solid white",
  },
};

const linkVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: { opacity: 1, y: 0 },
};

const imageVariants = {
  initial: { opacity: 0.6 },
  hover: { opacity: 1 },
};

const LandingPageKnownFrom = () => {
  const [hoveredId, setHoveredId] = useState(null);

  return (
    <div className="lg:max-w-[1216px] lg:mx-auto flex flex-col items-center justify-center gap-20 lg:py-28">
      <h4 className="w-full text-center flowGradientText font-light leading-snug">
        Bekannt aus:
      </h4>
      <div className="grid lg:grid-cols-4 lg:gap-4 grid-cols-1">
        {knownFrom.map((item) => (
          <motion.div
            key={item.id}
            className="rounded-full size-[304px] border border-[#666] flex justify-center items-center flex-col relative overflow-hidden"
            variants={circleVariants}
            initial="initial"
            animate={hoveredId === item.id ? "hover" : "initial"}
            onHoverStart={() => setHoveredId(item.id)}
            onHoverEnd={() => setHoveredId(null)}
          >
            <motion.div
              className="flex flex-col items-center justify-center h-full w-full"
              style={{
                background: hoveredId === item.id
                  ? "radial-gradient(210.77% 113.26% at 5.95% 7.14%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(136deg, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0.00) 97.5%), #34B2FF"
                  : "transparent",
              }}
            >
              <motion.img
                src={item.logo}
                alt={item.name}
                className="w-auto h-10"
                variants={imageVariants}
                initial="initial"
                animate={hoveredId === item.id ? "hover" : "initial"}
              />
              <motion.a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-row items-center justify-center gap-3 absolute transform -translate-x-1/2 bottom-1/4 text-white"
                variants={linkVariants}
                initial="hidden"
                animate={hoveredId === item.id ? "visible" : "hidden"}
              >
                <span>Zum Artikel</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="13"
                  height="5"
                  viewBox="0 0 13 5"
                  fill="none"
                >
                  <path
                    d="M12.1768 2.67678C12.2744 2.57915 12.2744 2.42085 12.1768 2.32322L10.5858 0.732233C10.4882 0.634602 10.3299 0.634602 10.2322 0.732233C10.1346 0.829864 10.1346 0.988155 10.2322 1.08579L11.6464 2.5L10.2322 3.91421C10.1346 4.01184 10.1346 4.17014 10.2322 4.26777C10.3299 4.3654 10.4882 4.3654 10.5858 4.26777L12.1768 2.67678ZM0 2.75H12V2.25H0V2.75Z"
                    fill="white"
                  />
                </svg>
              </motion.a>
            </motion.div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default LandingPageKnownFrom;