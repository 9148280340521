import React, { useState } from "react";

const LandingPageSpotsLeft = () => {
  const [hoverStates, setHoverStates] = useState([false, false]);

  const handleMouseEnter = (index) => {
    setHoverStates((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });
  };

  const handleMouseLeave = (index) => {
    setHoverStates((prev) => {
      const newState = [...prev];
      newState[index] = false;
      return newState;
    });
  };

  return (
    <section className="lg:max-w-[1216px] lg:mx-auto lg:py-28 py-20 flex flex-col justify-start items-start gap-16 px-4">
      <h2 className="text-4xl lg:text-5xl font-light pb-8 text-center lg:text-left">
        Hol dir jetzt einen der letzten 2 Plätze
        <br />
        für unseren <span className="text-flowPrimary">kostenloses Audit.</span>
      </h2>

      <div className="grid lg:grid-cols-5 grid-cols-1 w-full gap-6 justify-items-center">
        {[1, 2, 3].map((num) => (
          <div key={num} className="size-56 rounded-full border-[#666] border flex justify-center items-center relative">
            <div className="w-[calc(100%_-_10%)] h-[1px] absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-45 bg-[#666]"></div>
            <div className="flex flex-col items-center justify-center gap-3 text-[#666] font-light">
              <span className="text-[10px]">Gebucht</span>
              <span className="text-[20px]">Platz {num}</span>
            </div>
          </div>
        ))}
        {[4, 5].map((num, index) => (
          <div
            key={num}
            className="size-56 rounded-full border-flowPrimary border flex justify-center items-center relative transition-all duration-300"
            style={{
              background: hoverStates[index]
                ? "radial-gradient(210.77% 113.26% at 5.95% 7.14%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(136deg, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0.00) 97.5%), #34B2FF"
                : "transparent",
            }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={() => handleMouseLeave(index)}
          >
            {hoverStates[index] && (
              <>
                <div className="rounded-full absolute border size-60 border-flowPrimary opacity-20 animate-pulsateNoOpacity"></div>
                <div className="rounded-full absolute border size-72 border-flowPrimary opacity-10 animate-pulsateNoOpacity"></div>
                <div className="rounded-full absolute border size-80 border-flowPrimary opacity-5 animate-pulsateNoOpacity"></div>
              </>
            )}
            <div className={`flex flex-col items-center justify-center gap-3 font-light z-20 ${hoverStates[index] ? 'text-flowWhite' : 'text-flowWhite'}`}>
              <span className="text-[10px]">Verfügbar</span>
              <span className="text-[20px]">Platz {num}</span>
              {hoverStates[index] && (
                <a
                  href="#contact"
                  className="flex justify-center items-center gap-2"
                >
                  <span>Anmelden</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="5"
                    viewBox="0 0 13 5"
                    fill="none"
                  >
                    <path
                      d="M12.6768 2.67678C12.7744 2.57915 12.7744 2.42085 12.6768 2.32322L11.0858 0.732233C10.9882 0.634602 10.8299 0.634602 10.7322 0.732233C10.6346 0.829864 10.6346 0.988155 10.7322 1.08579L12.1464 2.5L10.7322 3.91421C10.6346 4.01184 10.6346 4.17014 10.7322 4.26777C10.8299 4.3654 10.9882 4.3654 11.0858 4.26777L12.6768 2.67678ZM0.5 2.75H12.5V2.25H0.5V2.75Z"
                      fill="white"
                    />
                  </svg>
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LandingPageSpotsLeft;