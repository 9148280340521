import React from "react";
import VideoPlayerWithLightbox from "../../landingpage/VideoPlayer";

const VideoAndWhitepaper = () => {
  return (
    <div className="lg:py-28 flex flex-col lg:flex-row justify-between items-between gap-6">
      <div className="lg:h-[578px] h-auto lg:w-1/2">
        <VideoPlayerWithLightbox />
      </div>

      <div className="flex flex-col justify-between items-between lg:max-w-[50%] lg:h-[578px] gap-10">
        <h2 className="text-flowGray text-flow-36 font-light leading-snug dark:text-[#F6F5EE]">
          Entdecke die Macht von Google Ads mit unseren{" "}
          <span className="flowGradientText">individuellen Leistungen.</span>
        </h2>
        <p className="text-flowGray dark:text-[#F9F8F7] lg:text-flow-20 leading-snug tracking-[0.8px] font-light">
          Lörem ipsum deng redytrel, desfili sekint, respektive diast. Sakoska
          dysskop saning. Far. Pov homoning inte vigon emedan pros katrengar.
          Plusjobb såst non. Antilingar kvasint för ask synmani. Äsör kobelt
          krosat krosat. Migisk bende och ultraren. Faning multin timide rekrati
          onaktig. Apocentrism kontrapabelt jag naporad kost, då lött.
          Sms-livräddare såren tvillingshoppare kesat, hemirade. Nyskap novis
          åkapons och vavis. Dyr doras. Hemiheten spesm mikrojys. Poling
          kubtest. Propladade pedyst biotirad.
        </p>

        <button className="text-flowGray dark:text-flowWhite flex flex-row justify-center items-center gap-3 py-4 px-6 border border-[#34B2FF] rounded-2xl max-w-[440px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2 11V12.5C2 13.3284 2.67157 14 3.5 14H12.5C13.3284 14 14 13.3284 14 12.5V11M11 8L8 11M8 11L5 8M8 11V2"
              className=" stroke-flowGray  dark:stroke-flowWhite"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p>Download unseres Google Ads Whitepapers</p>
        </button>
      </div>
    </div>
  );
};

export default VideoAndWhitepaper;
