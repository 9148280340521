import React from "react";
import SummarySection from "./SummaryMeter";
import KpiCard from "./KpiCard";

const KpisAndSummary = ({ analysisData, dashboardData }) => {
  if (!dashboardData || !analysisData) {
    return <div>Loading data...</div>;
  }

  console.log("KpisAndSummary_DASHBOARD_DATA", dashboardData);
  console.log("KpisAndSummary_Analysis_Data", analysisData);
  console.log("KpisAndSummary_Analysis_Data", analysisData.overall_score);

  const campaigns = dashboardData.campaigns || [];

  // Calculate KPIs
  const conversionValue = campaigns.reduce(
    (sum, campaign) => sum + (campaign.conversions_value || 0),
    0
  );
  const clicks = campaigns.reduce(
    (sum, campaign) => sum + (campaign.clicks || 0),
    0
  );
  const impressions = campaigns.reduce(
    (sum, campaign) => sum + (campaign.impressions || 0),
    0
  );
  const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0;
  const cost = campaigns.reduce(
    (sum, campaign) => sum + (campaign.cost_micros || 0),
    0
  );
  const roas = cost > 0 ? conversionValue / cost : 0;
  const conversions = campaigns.reduce(
    (sum, campaign) => sum + (campaign.conversions || 0),
    0
  );
  const avgConvValue = conversions > 0 ? conversionValue / conversions : 0;

  return (
    <div className="flex flex-col lg:flex-row items-start gap-4">
      <div className="lg:flex flex-row flex-wrap justify-start lg:max-w-[38%] gap-3 w-full lg:w-auto items-start grid grid-cols-1">
        <KpiCard
          title="Conversion Wert"
          value={`${conversionValue.toFixed(2)}€`}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="CTR"
          value={`${ctr.toFixed(2)}%`}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="ROAS"
          value={roas.toFixed(2)}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="Impressionen"
          value={impressions.toLocaleString()}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="Durchschn. Conv.-Wert"
          value={`${avgConvValue.toFixed(2)}€`}
          change={-3.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="Kosten"
          value={`${cost.toFixed(2)}€`}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="Klicks"
          value={clicks.toLocaleString()}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
        <KpiCard
          title="Conversions"
          value={conversions.toLocaleString()}
          change={-3.01}
          info="Wie verbessern?"
          expandInfo="Lorem ipsum dolor sit amet, consectetur adipiscing elit"
        />
      </div>
      <div className="w-full lg:flex-1 flex-shrink-0">
        <SummarySection analysisData={analysisData} />
      </div>
    </div>
  );
};

export default KpisAndSummary;
