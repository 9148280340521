import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';
import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="272589557609-3mk802rba05u1ev6irej9ss0pppdaekv.apps.googleusercontent.com">
      <App />
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
);