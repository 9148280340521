import React from 'react';
import { Link } from 'react-router-dom';

const CustomButton = ({ href, text, icon, onClick, className = '' }) => {
  const buttonClasses = `w-44 h-10 px-6 py-2 rounded-lg border border-flowPrimary dark:!bg-flowDark flowGradientWhiteMode flex justify-center items-center gap-2.5 text-center text-white text-xs font-normal leading-tight tracking-wide transition-colors duration-300 ${className}`;

  const content = (
    <>
      {icon && <span className="mr-2">{icon}</span>}
      {text}
    </>
  );

  if (href) {
    return (
      <Link to={href} className={buttonClasses}>
        {content}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className={buttonClasses}>
      {content}
    </button>
  );
};

export default CustomButton;