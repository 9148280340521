import React, { useState, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import coin from "../../icons/coin.svg";
import dbSearch from "../../icons/dbSearch.svg";
import okrs from "../../icons/okrs.svg";
import chart from "../../icons/chart.svg";

const cardVariants = {
    initial: {
      backgroundColor: "transparent",
      border: "1px solid #666",
    },
    active: {
      backgroundColor: "white",
      border: "1px solid white",
    },
  };

  const contentVariants = {
    initial: { color: "white" },
    active: { color: "#333" },
  };

  const descriptionVariants = {
    initial: { opacity: 0, height: 0 },
    animate: {
      opacity: 1,
      height: "auto",
      transition: { duration: 0.3 },
    },
    exit: {
      opacity: 0,
      height: 0,
      transition: { duration: 0.2 },
    },
  };

  const iconVariants = {
    initial: { scale: 1, x: 0, y: 0, color: "white" },
    active: {
      scale: 2,
      x: "-50%",
      y: "-50%",
      left: "50%",
      top: "30%",
      color: "#34B2FF",
      duration: 0.3,
    },
  };

  const FeatureCard = ({ icon: Icon, title, description }) => {
    const [isActive, setIsActive] = useState(false);

    const handleInteraction = useCallback(() => {
      setIsActive((prev) => !prev);
    }, []);

    return (
      <motion.div
        className="rounded-xl py-6 px-4 h-[360px] lg:w-[280px] w-full flex flex-col justify-start items-start overflow-hidden relative "
        variants={cardVariants}
        initial="initial"
        animate={isActive ? "active" : "initial"}
        onHoverStart={handleInteraction}
        onHoverEnd={handleInteraction}
        onClick={handleInteraction}
        transition={{ duration: 0.3 }}
      >
        <motion.div
          variants={iconVariants}
          transition={{ duration: 0.3 }}
          className="absolute top-6 left-4"
        >
          <Icon size={24} />
        </motion.div>
        <motion.div
          variants={contentVariants}
          className="flex h-full w-full flex-col items-start justify-end mt-12"
        >
          <div className="w-full">
            <h3 className="text-xl font-normal mb-4 ">{title}</h3>
            <AnimatePresence>
              {isActive && (
                <motion.p
                  className="text-sm text-[#4f4f4f] font-light"
                  variants={descriptionVariants}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  {description}
                </motion.p>
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </motion.div>
    );
  };

const GoogleAdsAuditFeatures = () => {
  const features = [
    {
      icon: () => <img src={dbSearch} alt="Database Search" />,
      title: "Optimierungspotenziale erkennen",
      description:
        "Identifizieren Sie verborgene Möglichkeiten zur Verbesserung Ihrer Kampagnen.",
    },
    {
      icon: () => <img src={chart} alt="Chart" />,
      title: "Wettbewerbsvorteil sichern",
      description:
        "Durch datengetriebene Erkenntnisse und aktuelle Best Practices einen Vorsprung erlangen",
    },
    {
      icon: () => <img src={coin} alt="Coin" />,
      title: "Maximale Ergebnisse kosteneffizient erzielen",
      description: "Optimieren Sie Ihr Budget für die bestmögliche Rendite.",
    },
    {
      icon: () => <img src={okrs} alt="OKRs" />,
      title: "Personalisierte Empfehlungen",
      description:
        "Erhalten Sie maßgeschneiderte Vorschläge für Ihre spezifischen Kampagnenziele.",
    },
  ];

  return (
    <div className="p-8  rounded-xl lg:max-w-[1280px] lg:py-28 py-20 lg:mx-auto">
      <h2 className="font-light text-[#F6F5EE] mb-8 lg:text-right lg:text-5xl text-flow-36 lg:leading-normal text-center">
        Das kannst du erwarten von unserem <br />
        <span className="flowGradientText">Google Ads Audit</span>
      </h2>
      <div className="flex flex-wrap justify-center gap-8">
        {features.map((feature, index) => (
          <FeatureCard key={index} {...feature} />
        ))}
      </div>
    </div>
  );
};

export default GoogleAdsAuditFeatures;
