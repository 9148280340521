import React from "react";
import LandingpageNav from "./LandingpageNav";
import LandingPageHeroVideo from "./LandingPageHeroVideo";
import LandingPageMarquee from "./LandingpageMarquee";
import InfoandTeaser from "./InfoandTeaser";
import GoogleAdsAuditFeatures from "./LandingPageCards";
import ScrollSteps from "./LandingPageThreeSteps";
import LandingPageNumbers from "./LandingPageNumbers";
import LandingPageKnownFrom from "./LandingPageKnownFrom";
import ServiceAccordion from "./LandingPageAccordion";
import FaqAccordion from "./LandingPageFaq";
import LandingPageSpotsLeft from "./LandingPageSpotsLeft";
import LandingPageTestimonials from "./LandingPageTestimonials";
import LandingPageContact from "./LandingPageContact";
import LandingPageFooter from "./LandingPageFooter";


const LandingPage = () => {
  return (
    <>
    <div className="min-h-screen bg-[#1A1B1D] lg:pt-12 text-flowWhite">
      {<LandingpageNav />}
      {<LandingPageHeroVideo />}
      {<LandingPageMarquee />}
      {<InfoandTeaser />}
      {<GoogleAdsAuditFeatures />}
      {<ScrollSteps />}
      {<LandingPageNumbers />}
      {<LandingPageKnownFrom />}
      {<ServiceAccordion />}
      {<LandingPageSpotsLeft />}
      {<LandingPageTestimonials />}
      {<FaqAccordion />}
      {<LandingPageContact />}
      {<LandingPageFooter />}
    </div>
    </>
  );
};

export default LandingPage;
