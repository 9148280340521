import React from "react";

const Recommendations = ({ analysisData }) => {
  // Check if analysisData and recommendations exist
  if (!analysisData || !analysisData.recommendations) {
    return null; // or you could return a placeholder component
  }

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-flowGray dark:text-white lg:text-flow-20 tracking-[0.8px]">
        Empfehlungen
      </h2>
      <div className="dashboardShadow p-8 dark:border rounded-2xl dark:border-[#5E5E5E]">
        {analysisData.recommendations.length > 0 ? (
          analysisData.recommendations.map((recommendation, index) => (
            <div
              key={index}
              className={`text-[14px] tracking-[0.56px] leading-normal text-flowGray dark:text-white mb-4 ${
                index === 1 || index === 2 ? "blur-sm opacity-50" : ""
              }`}
            >
              <div className="flex flex-row items-center justify-start gap-2 mb-3">
                <div className="size-2 flowPill rounded-full"></div>
                <h3 className="text-flow-12 tracking-[0.48px] font-medium">
                  {recommendation.suggestion}:{" "}
                </h3>
              </div>
              <p className="text-flow-12 font-light tracking-[0.48px]">
                {recommendation.details}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500 dark:text-gray-400">
            No recommendations available.
          </p>
        )}
      </div>
    </div>
  );
};

export default Recommendations;
