import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay } from "swiper/modules";

const testimonials = [
  {
    id: 1,
    name: "John Doe",
    text: "“Lörem ipsum stalker aska. Processturism rilugon sugt. Div tral degt, inte abfaktisk. Paravalens realulingar stenonat, i ar devådat. Husperade biosmos diskade: emedan semiktig. Neoaktiv besirad. Beska besade mjuk betalvägg, hörade. Gigaska. Divis megaskapet lanar då previs som pesk. Deligt radogt. Epin provalens makrosk, kass. Fätt med",
    company: "Company",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "John Doe",
    text: "“Lörem ipsum stalker aska. Processturism rilugon sugt. Div tral degt, inte abfaktisk. Paravalens realulingar stenonat, i ar devådat. Husperade biosmos diskade: emedan semiktig. Neoaktiv besirad. Beska besade mjuk betalvägg, hörade. Gigaska. Divis megaskapet lanar då previs som pesk. Deligt radogt. Epin provalens makrosk, kass. Fätt med",
    company: "Company",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "John Doe",
    text: "“Lörem ipsum stalker aska. Processturism rilugon sugt. Div tral degt, inte abfaktisk. Paravalens realulingar stenonat, i ar devådat. Husperade biosmos diskade: emedan semiktig. Neoaktiv besirad. Beska besade mjuk betalvägg, hörade. Gigaska. Divis megaskapet lanar då previs som pesk. Deligt radogt. Epin provalens makrosk, kass. Fätt med",
    company: "Company",
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    name: "John Doe",
    text: "“Lörem ipsum stalker aska. Processturism rilugon sugt. Div tral degt, inte abfaktisk. Paravalens realulingar stenonat, i ar devådat. Husperade biosmos diskade: emedan semiktig. Neoaktiv besirad. Beska besade mjuk betalvägg, hörade. Gigaska. Divis megaskapet lanar då previs som pesk. Deligt radogt. Epin provalens makrosk, kass. Fätt med",
    company: "Company",
    image: "https://via.placeholder.com/150",
  },
];

const LandingPageTestimonials = () => {
  return (
    <section
      className="lg:max-w-[1216px] lg:mx-auto lg:py-28 px-4"
      id="testimonials"
    >
      <div className="flex lg:flex-row flex-col justify-between items-start mb-8 w-full">
        <h2 className="text-4xl lg:text-5xl font-light mb-8 lg:mb-0">
          <span className="text-flowPrimary">Überzeuge Dich selbst</span> von
          den
          <br />
          Erfahrungen unserer Kunden.
        </h2>
        <div className="hidden lg:flex gap-4 w-auto mr-0 ml-auto justify-end">
          <button className="custom-prev-button !static !w-10 !h-10 border border-flowPrimary rounded-full after:!text-white after:!text-base">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="4"
              viewBox="0 0 17 4"
              fill="none"
              className="mx-auto rotate-180"
            >
              <path
                d="M16.1768 2.17678C16.2744 2.07915 16.2744 1.92085 16.1768 1.82322L14.5858 0.232233C14.4882 0.134602 14.3299 0.134602 14.2322 0.232233C14.1346 0.329864 14.1346 0.488155 14.2322 0.585786L15.6464 2L14.2322 3.41421C14.1346 3.51184 14.1346 3.67014 14.2322 3.76777C14.3299 3.8654 14.4882 3.8654 14.5858 3.76777L16.1768 2.17678ZM0 2.25H16V1.75H0V2.25Z"
                fill="white"
              />
            </svg>
          </button>
          <button className="custom-next-button flex items-center justify-center!static !w-10 !h-10 border border-flowPrimary rounded-full after:!text-white after:!text-base">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="4"
              viewBox="0 0 17 4"
              fill="none"
              className="mx-auto"
            >
              <path
                d="M16.1768 2.17678C16.2744 2.07915 16.2744 1.92085 16.1768 1.82322L14.5858 0.232233C14.4882 0.134602 14.3299 0.134602 14.2322 0.232233C14.1346 0.329864 14.1346 0.488155 14.2322 0.585786L15.6464 2L14.2322 3.41421C14.1346 3.51184 14.1346 3.67014 14.2322 3.76777C14.3299 3.8654 14.4882 3.8654 14.5858 3.76777L16.1768 2.17678ZM0 2.25H16V1.75H0V2.25Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
        <div className="relative h-10 w-full lg:hidden block">
          <div className="swiper-pagination !lg:hidden !w-[calc(100%_-6rem)]"></div>
          <div className="lg:hidden flex justify-end gap-4 absolute -top-5 right-0 z-20">
            <button className="custom-prev-button !static !w-10 !h-10 border border-flowPrimary rounded-full after:!text-white after:!text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="4"
                viewBox="0 0 17 4"
                fill="none"
                className="mx-auto rotate-180"
              >
                <path
                  d="M16.1768 2.17678C16.2744 2.07915 16.2744 1.92085 16.1768 1.82322L14.5858 0.232233C14.4882 0.134602 14.3299 0.134602 14.2322 0.232233C14.1346 0.329864 14.1346 0.488155 14.2322 0.585786L15.6464 2L14.2322 3.41421C14.1346 3.51184 14.1346 3.67014 14.2322 3.76777C14.3299 3.8654 14.4882 3.8654 14.5858 3.76777L16.1768 2.17678ZM0 2.25H16V1.75H0V2.25Z"
                  fill="white"
                />
              </svg>
            </button>
            <button className="custom-next-button !static !w-10 !h-10 border border-flowPrimary rounded-full after:!text-white after:!text-base">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="4"
                viewBox="0 0 17 4"
                fill="none"
                className="mx-auto"
              >
                <path
                  d="M16.1768 2.17678C16.2744 2.07915 16.2744 1.92085 16.1768 1.82322L14.5858 0.232233C14.4882 0.134602 14.3299 0.134602 14.2322 0.232233C14.1346 0.329864 14.1346 0.488155 14.2322 0.585786L15.6464 2L14.2322 3.41421C14.1346 3.51184 14.1346 3.67014 14.2322 3.76777C14.3299 3.8654 14.4882 3.8654 14.5858 3.76777L16.1768 2.17678ZM0 2.25H16V1.75H0V2.25Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div className="relative">
        <Swiper
          pagination={{
            type: "progressbar",
            el: ".swiper-pagination",
            clickable: true,
          }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={{
            prevEl: ".custom-prev-button",
            nextEl: ".custom-next-button",
          }}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="py-10 flex flex-col justify-start items-start gap-14 lg:pl-[33%]">
                <p className="text-[#F9F8F7] font-light tracking-[0.8px] text-flow-20 leading-snug">
                  {testimonial.text}
                </p>
                <div className="flex flex-row items-center justify-center gap-4">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="rounded-full object-cover size-20"
                  />
                  <div>
                    <h3 className="text-white">{testimonial.name}</h3>
                    <p className="text-[12px] text-[#C1C1C1]">
                      {testimonial.company}
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>

      <style jsx global>{`
        .swiper-pagination-progressbar{
            border-radius: 24px;
        }
        .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
          background: radial-gradient(
              210.77% 113.26% at 5.95% 7.14%,
              rgba(255, 255, 255, 0.5) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(
              136deg,
              rgba(255, 255, 255, 0.23) 0%,
              rgba(255, 255, 255, 0) 97.5%
            ),
            #34b2ff !important;
        }
        .swiper-pagination {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
      `}</style>
    </section>
  );
};

export default LandingPageTestimonials;
