import React from "react";
import { Link } from "react-router-dom";
import FlowLogo from "../../icons/FlowLogo";

const NavLink = ({ to, children }) => {
  const isAnchorLink = to.startsWith("#");

  const handleClick = (e) => {
    if (isAnchorLink) {
      e.preventDefault();
      const element = document.querySelector(to);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const className =
    "text-[#F9F8F7] text-base font-light leading-tight tracking-wide " +
    "hover:text-white transition-colors duration-200 " +
    "mr-4 md:mr-6 lg:mr-8 last:mr-0";

  if (isAnchorLink) {
    return (
      <a href={to} className={className} onClick={handleClick}>
        {children}
      </a>
    );
  }

  return (
    <Link to={to} className={className}>
      {children}
    </Link>
  );
};

const navItems = [
  { to: "#works", label: "How it works" },
  { to: "#testimonials", label: "Testimonials" },
  { to: "#faq", label: "FAQs" },
  { to: "/contact", label: "Contact us" },
];

const LandingpageNav = () => {
  return (
    <nav className="flex flex-wrap px-4 justify-between items-center space-x-2 md:space-x-4 lg:space-x-6  md:py-4 py-12 lg:max-w-[1216px] lg:mx-auto">
      <FlowLogo width="128px" length="auto" />
      <div className="hidden md:flex flex-row">
        {navItems.map((item) => (
          <NavLink key={item.to} to={item.to}>
            {item.label}
          </NavLink>
        ))}
      </div>

      <Link to={"/analysis"}>
        <button className="bg-white rounded-xl text-[#333] flex items-center justify-center py-3 px-6 flowGradient  transition-all">
          <span>Anmelden</span>
        </button>
      </Link>
    </nav>
  );
};

export default LandingpageNav;
