import React from "react";

const Insights = ({ analysisData }) => {
  // Check if analysisData and detailed_insights exist
  if (!analysisData || !analysisData.detailed_insights) {
    return null; // or you could return a placeholder component
  }

  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-flowGray dark:text-white lg:text-flow-20 tracking-[0.8px]">
        Detailierte Einblicke
      </h2>
      <div className="dashboardShadow p-8 dark:border rounded-2xl dark:border-[#5E5E5E]">
        {analysisData.detailed_insights.length > 0 ? (
          analysisData.detailed_insights.map((insight, index) => (
            <div
              key={index}
              className="text-[14px] tracking-[0.56px] leading-normal text-flowGray dark:text-white mb-4"
            >
              <div className="flex flex-row items-center justify-start gap-2 mb-3">
                <div className="size-2 flowPill rounded-full"></div>
                <h3 className="text-flow-12 tracking-[0.48px] font-medium">
                  {insight.topic}:{" "}
                </h3>
              </div>
              <p className="text-flow-12 font-light tracking-[0.48px]">
                {insight.details}
              </p>
            </div>
          ))
        ) : (
          <p className="text-gray-500 dark:text-gray-400">
            No insights available.
          </p>
        )}
      </div>
    </div>
  );
};

export default Insights;
