import React, { useEffect, useState } from "react";
import QualityScoreDisplay from "./QualityScoreDisplay";
import ContactCard from "./ContactCard";

const QualityScoreCard = ({ score }) => {
  const getScoreText = (score) => {
    if (score >= 1 && score <= 4) {
      return {
        headline: "Ihr Quality Score ist niedrig",
        text: "Ihr Konto hat einen niedrigen Qualitätsfaktor. Sie können Ihre Anzeigenplatzierungen deutlich verbessern und Ihre Kosten pro Klick senken, wenn Sie grundlegende Anpassungen an Ihren Keywords, Anzeigentexten und Landing Pages vornehmen. Fokussieren Sie sich auf die Relevanz und Qualität Ihrer Anzeigen und Zielseiten.",
      };
    } else if (score >= 5 && score <= 8) {
      return {
        headline: "Ihr Quality Score ist durchschnittlich",
        text: "Ihr Konto hat einen durchschnittlichen Qualitätsfaktor. Sie können Ihre Anzeigenplatzierungen verbessern und Ihre Kosten pro Klick senken, wenn Sie einige Anpassungen an Ihren Keywords und Landing Pages vornehmen. Die unten stehenden Diagramme bieten wertvolle Einblicke und Empfehlungen, um Ihnen zu helfen, höhere Qualitätsfaktoren zu erzielen und effektiver zu werben.",
      };
    } else if (score >= 9 && score <= 10) {
      return {
        headline: "Ihr Quality Score ist ausgezeichnet",
        text: "Herzlichen Glückwunsch! Ihr Konto hat einen hervorragenden Qualitätsfaktor. Ihre Anzeigen sind hochrelevant und bieten eine ausgezeichnete Benutzererfahrung. Halten Sie dieses Niveau, indem Sie weiterhin auf Relevanz und Qualität achten. Die unten stehenden Diagramme können Ihnen helfen, Ihre Leistung weiter zu optimieren.",
      };
    } else {
      return {
        headline: "Ungültiger Score",
        text: "Bitte überprüfen Sie den eingegebenen Wert. Der Quality Score sollte zwischen 1 und 10 liegen.",
      };
    }
  };

  const scoreInfo = getScoreText(score);

  return (
    <div className="flex flex-col lg:flex-row justify-center items-center w-full lg:justify-between  lg:items-stretch gap-8">
      <div className="flex flex-col lg:flex-row gap-9 items-center dark:border dark:border-[#5E5E5E] rounded-2xl p-8 dashboardShadow flex-shrink-0 lg:w-2/3">
        <QualityScoreDisplay score={score} />
        <div className="flex flex-col gap-3 dark:text-white text-[#333] lg:max-w-lg ">
          <h2 className="text-flow-20 text-center tracking-[0.8px]">
            {scoreInfo.headline}
          </h2>
          <p className=" text-flow-12 font-light text-center  tracking-[0.48px] leading-relaxed">
            {scoreInfo.text}
          </p>
        </div>
      </div>
      <div className="lg:w-1/3 w-full">
        <ContactCard
          title="Optimiere die Leistung"
          subtitle="Lörem ipsum aktigt homoss resogt faponivis"
          buttonText="Termin vereinbaren"
        />
      </div>
    </div>
  );
};

export default QualityScoreCard;
