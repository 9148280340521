import React from "react";
import Marquee from "react-fast-marquee";

const LandingPageMarquee = ({ hidePY = false }) => (
  <div
    className={`flex flex-col justify-center items-center gap-5 ${
      hidePY ? "" : "py-40"
    }`}
  >
    <Marquee gradient={false} speed={40} autoFill={true}>
      <div className="flex items-center space-x-16 mx-8">
        <img
          src="/marquee/bentley.png"
          alt="Bentley"
          className="h-12 invert dark:invert-0"
        />
        <img
          src="/marquee/evernest.png"
          alt="Evernest"
          className="h-8 invert dark:invert-0"
        />
        <img
          src="/marquee/johnMastersOrganics.png"
          alt="John Masters Organics"
          className="h-10 invert dark:invert-0"
        />
        <img
          src="/marquee/medudy.png"
          alt="Medudy"
          className="h-8 invert dark:invert-0"
        />
        <img
          src="/marquee/withinMood.png"
          alt="Within Mood"
          className="h-8 invert dark:invert-0"
        />
      </div>
    </Marquee>
    <Marquee gradient={false} speed={40} autoFill={true} direction="right">
      <div className="flex items-center space-x-16 mx-8 invert dark:invert-0">
        <img
          src="/marquee/johnMastersOrganics.png"
          alt="John Masters Organics"
          className="h-10"
        />
        <img src="/marquee/bentley.png" alt="Bentley" className="h-12" />
        <img src="/marquee/evernest.png" alt="Evernest" className="h-8" />
        <img src="/marquee/medudy.png" alt="Medudy" className="h-8" />
        <img src="/marquee/withinMood.png" alt="Within Mood" className="h-8" />
      </div>
    </Marquee>
  </div>
);

export default LandingPageMarquee;
