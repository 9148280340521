import React, { useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainAnalyzer from './components/analyzer/MainAnalyzer';
import LandingPage from './components/landingpage/LandingPage';
import './fonts.css';
import { Analytics } from '@vercel/analytics/react';
function App() {


  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/analysis" element={<MainAnalyzer />} />
      </Routes>
      <Analytics />
    </Router>

  );
}

export default App;