import React from "react";
import LandingPageMarquee from "../../landingpage/LandingpageMarquee";

const MarqueeWithHeadline = () => {
  return (
    <div className="flex flex-col gap-16 mt-20">
      <h2 className="dark:text-flowWhite w-full text-center font-light lg:text-flow-36 text-[32px]">
        Kunden die durchs Audit bereits<br></br>{" "}
        <span className="flowGradientText">erfolgreich</span> geworden sind
      </h2>
      <LandingPageMarquee hidePY={true} />
    </div>
  );
};

export default MarqueeWithHeadline;
