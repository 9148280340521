import React, { useState } from "react";
import { Popover, ArrowContainer } from "react-tiny-popover";

const QualityScoreDisplay = ({ score }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const totalDots = 20;
  const filledDots = Math.round((score / 10) * totalDots);

  const getColor = (score) => {
    if (score >= 1 && score <= 4) return "#FF3B30";
    if (score >= 5 && score <= 8) return "#FFBD00";
    if (score >= 9 && score <= 10) return "#34C759";
    return "#5E5E5E"; // Default color for invalid scores
  };

  const color = getColor(score);

  return (
    <div className="relative lg:size-56 flex-shrink-0">
      <svg
        className="w-full h-full"
        viewBox="0 0 222 225"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {[...Array(totalDots)].map((_, index) => {
          const angle = (index / totalDots) * Math.PI * 2 - Math.PI / 2;
          const cx = 111 + Math.cos(angle) * 100;
          const cy = 112.5 + Math.sin(angle) * 100;
          const filled = index < filledDots;

          return (
            <circle
              key={index}
              cx={cx}
              cy={cy}
              r="6.81615"
              fill={filled ? color : "#5E5E5E"}
              fillOpacity={filled ? 1 : 0.3}
            />
          );
        })}
      </svg>
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-flowGray dark:text-white">
        <div className="relative flex flex-col items-center justify-center text-center text-flowGray dark:text-white">
          <div className="absolute top-0 right-0">
            <Popover
              isOpen={isPopoverOpen}
              positions={["top", "left"]} // if you'd like, you can limit the positions
              reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
              onClickOutside={() => setIsPopoverOpen(false)} // handle click events outside of the popover/target here!
              content={({ position, nudgedLeft, nudgedTop }) => (
                <div className="border bg-flowDark rounded-lg p-8 border-[#5E5E5E] text-white max-w-sm relative z-10">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="27"
                    height="14"
                    viewBox="0 0 27 14"
                    fill="none"
                    className="absolute -bottom-[14px] left-1/2 transform -translate-x-1/2 z-20"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.3887 1.06622e-06L1 0L13.1943 12.1943L25.3887 1.06622e-06Z"
                      fill="#1A1B1D"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0.293015 0L13.1466 12.8536L13.5001 13.2071L13.8537 12.8536L26.7072 0H25.293L13.5001 11.7929L1.70723 0H0.293015Z"
                      fill="#5E5E5E"
                    />
                  </svg>
                  <div>
                    <p>
                      Der Flow Qualtätsfaktor ist eine KI unterstütze
                      Evaluierung alle Daten die sich im Konto befinden. Er
                      berechnet sich basierend auf den Werten von Google, dem
                      FLOW interen Vergleichsindex und verschiedenen anderen
                      Datenpunkten{" "}
                    </p>
                  </div>
                </div>
              )}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              >
                <path
                  d="M5.625 5.625L5.64574 5.61463C5.9323 5.47135 6.25496 5.73017 6.17725 6.04099L5.82275 7.45901C5.74504 7.76983 6.0677 8.02865 6.35426 7.88537L6.375 7.875M10.5 6C10.5 8.48528 8.48528 10.5 6 10.5C3.51472 10.5 1.5 8.48528 1.5 6C1.5 3.51472 3.51472 1.5 6 1.5C8.48528 1.5 10.5 3.51472 10.5 6ZM6 4.125H6.00375V4.12875H6V4.125Z"
                  stroke="white"
                  stroke-width="0.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Popover>
          </div>

          <span className="lg:text-[32px] tracking-[1.28px] ">{score}/10</span>
          <span className="tracking-[0.64px]">Qualitätsfaktor</span>
        </div>
      </div>
    </div>
  );
};

export default QualityScoreDisplay;
