import React from "react";

function LandingPageFooter() {
  return (
    <footer className="lg:max-w-[1216px] mx-auto border-t border-[#666] pt-10 pb-28 flex lg:flex-row flex-col justify-between items-center px-4 lg:px-0 gap-10 lg:gap-0">
      <div className="order-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="131"
          height="61"
          viewBox="0 0 131 61"
          fill="none"
        >
          <path
            d="M74.85 5.36594C81.2621 11.8569 80.0279 19.0719 87.544 23.7249C87.544 23.7249 91.2575 25.7828 93.1813 21.443C95.1051 17.1033 94.5778 13.9654 107.648 14.0811C120.718 14.1968 130.954 13.5026 130.954 13.5026C130.954 13.5026 126.906 12.1148 112.217 11.5944C97.5274 11.074 94.3469 12.5776 91.802 18.3605C89.2577 24.1433 86.3081 19.7483 85.1517 17.8976C83.9952 16.047 81.3593 10.8144 81.3593 10.8144C81.3593 10.8144 78.012 3.64766 73.3441 1.38481C68.6762 -0.878032 63.4903 1.43201 58.4454 5.67506C58.0171 6.03541 57.6003 6.3808 57.1928 6.71294C50.3876 12.2569 42.0385 15.5611 33.2818 16.1684C33.2081 16.1736 33.1333 16.1788 33.059 16.1834C25.6321 16.6767 0 14.8686 0 14.8686C0 14.8686 26.4098 19.7103 40.1648 17.4285C40.3115 17.4043 40.4572 17.3796 40.6022 17.3537C47.769 16.0993 54.4925 13.0197 60.1827 8.48534L61.1428 7.72031C61.726 7.2552 62.2964 6.77511 62.8565 6.28294C64.5696 4.77764 70.1493 0.607696 74.8494 5.36594H74.85Z"
            fill="url(#paint0_linear_6860_2197)"
          />
          <path
            d="M23.8359 15.4457C27.3577 14.5448 32.9264 8.28012 41.7303 8.60708C50.5336 8.93462 54.3869 13.4747 62.0752 21.0029C69.7634 28.5306 77.3147 30.7341 83.2824 21.875C89.2494 13.0159 98.0326 12.5974 98.0326 12.5974C107.022 11.4738 117.363 14.0526 117.363 14.0526C117.363 14.0526 112.558 13.9525 102.567 14.3082C98.8085 14.4418 95.8048 15.4762 93.5201 16.7156C90.9597 18.104 88.7993 20.1256 87.1863 22.5508C85.5884 24.9541 81.5318 30.3548 76.56 31.7185C70.1094 33.488 63.8395 28.3487 56.7465 20.3035C49.6534 12.2589 45.1784 11.1163 40.849 11.0628C36.5196 11.0098 31.3066 13.614 26.2104 15.8118C21.1143 18.009 4.14844 15.2954 4.14844 15.2954C4.14844 15.2954 16.5063 17.3701 23.8348 15.4457H23.8359Z"
            fill="url(#paint1_linear_6860_2197)"
          />
          <path
            d="M35.65 43.666V44.6561H26.244V51.5868H33.051V52.5769H26.244V60.4978H25.2539V43.666H35.65Z"
            fill="white"
          />
          <path
            d="M44.8086 43.666H45.7987V59.5077H54.7096V60.4978H44.8086V43.666Z"
            fill="white"
          />
          <path
            d="M71.2948 43.666C73.3901 43.666 75.3174 44.5036 77.0748 46.1787C78.8322 47.8538 79.7106 49.8213 79.7106 52.0819C79.7106 54.3424 78.8322 56.3105 77.0748 57.9857C75.3174 59.6608 73.3907 60.4978 71.2948 60.4978C69.1989 60.4978 67.2722 59.6602 65.5153 57.9857C63.7579 56.3111 62.8789 54.343 62.8789 52.0819C62.8789 49.8208 63.7579 47.8532 65.5153 46.1787C67.2728 44.5041 69.1994 43.666 71.2948 43.666ZM71.2948 59.5077C73.1426 59.5077 74.8424 58.7691 76.3938 57.2926C77.9446 55.8161 78.7205 54.0788 78.7205 52.0819C78.7205 50.085 77.9446 48.3483 76.3938 46.8718C74.8424 45.3952 73.1426 44.6561 71.2948 44.6561C69.447 44.6561 67.7465 45.3947 66.1957 46.8718C64.6444 48.3489 63.869 50.0856 63.869 52.0819C63.869 54.0782 64.6444 55.8155 66.1957 57.2926C67.7465 58.7697 69.4464 59.5077 71.2948 59.5077Z"
            fill="white"
          />
          <path
            d="M87.7031 43.666H88.7427L88.8417 44.0126L93.2229 58.7651L97.3566 51.8344L97.5051 51.5868H98.0497L98.1982 51.8344L102.332 58.7651L106.713 44.0126L106.812 43.666H107.852L107.654 44.3096L102.951 60.1512L102.852 60.4978H102.208L102.06 60.2502L97.7774 53.0472L93.4952 60.2502L93.3467 60.4978H92.7031L92.6041 60.1512L87.9011 44.3096L87.7031 43.666Z"
            fill="white"
          />
          <defs>
            <linearGradient
              id="paint0_linear_6860_2197"
              x1="0"
              y1="12.3565"
              x2="130.954"
              y2="12.3565"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#288AEF" />
              <stop offset="0.38" stop-color="#47A9E8" />
              <stop offset="0.77" stop-color="#62C4E3" />
              <stop offset="1" stop-color="#6DCFE2" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_6860_2197"
              x1="4.14959"
              y1="20.3312"
              x2="117.364"
              y2="20.3312"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#006AC1" />
              <stop offset="0.11" stop-color="#0773C7" />
              <stop offset="0.3" stop-color="#1D8ED7" />
              <stop offset="0.54" stop-color="#3FB8F2" />
              <stop offset="0.59" stop-color="#48C3F9" />
              <stop offset="0.61" stop-color="#4FC5F6" />
              <stop offset="0.67" stop-color="#6BCDEF" />
              <stop offset="0.73" stop-color="#80D3E9" />
              <stop offset="0.8" stop-color="#90D8E5" />
              <stop offset="0.88" stop-color="#99DBE2" />
              <stop offset="1" stop-color="#9CDCE2" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="text-flowWhite text-[12px] leading-[160%] order-3 lg:order-2 text-center lg:text-left">
        <p>FLOW Innovations</p>
        <p>Mittelweg 162</p>
        <p>20148 Hamburg</p>
      </div>
      <div className="grid grid-cols-1 text-flowWhite text-[12px] leading-[160%] order-4 lg:order-3 text-center lg:text-left">
        <a
          href="https://www.flow-in.com/impressum"
          target="_blank"
          rel="noreferrer"
        >
          Impressum
        </a>
        <a
          href="https://www.flow-in.com/datenschutz"
          target="_blank"
          rel="noreferrer"
        >
          Datenschutz
        </a>
      </div>
      <div className="flex lg:flex-row gap-3 flex-col w-full lg:w-auto order-2 lg:order-4">
        <a
          href="#contact"
          className="flex flex-row items-center justify-center border-[0.5px] rounded-3xl border-flowWhite flowGradient  py-4 px-6 gap-3 flowGradient transition-all dark:text-flowWhite"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M11.6185 1.83252H6.28516C3.61849 1.83252 2.28516 3.16585 2.28516 5.83252V14.4992C2.28516 14.8659 2.58516 15.1659 2.95182 15.1659H11.6185C14.2852 15.1659 15.6185 13.8325 15.6185 11.1659V5.83252C15.6185 3.16585 14.2852 1.83252 11.6185 1.83252Z"
              stroke="white"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.62109 6.83252H12.2878"
              stroke="white"
              stroke-width="0.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5.62109 10.166H10.2878"
              stroke="white"
              stroke-width="0.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span className="text-[12px]">Contact Form</span>
        </a>
        <a
          href="#contact"
          className="flex flex-row items-center justify-center border-[0.5px] rounded-3xl border-flowWhite flowGradient  py-4 px-6 gap-3 flowGradient transition-all dark:text-flowWhite"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="13"
            viewBox="0 0 17 13"
            fill="none"
          >
            <path
              d="M16.5531 2.29902V10.699C16.5531 11.6931 15.7472 12.499 14.7531 12.499H2.75313C1.75901 12.499 0.953125 11.6931 0.953125 10.699V2.29902M16.5531 2.29902C16.5531 1.30491 15.7472 0.499023 14.7531 0.499023H2.75313C1.75901 0.499023 0.953125 1.30491 0.953125 2.29902M16.5531 2.29902V2.49319C16.5531 3.11826 16.2288 3.69858 15.6965 4.02618L9.6965 7.71848C9.11797 8.0745 8.38828 8.0745 7.80975 7.71848L1.80975 4.02618C1.2774 3.69858 0.953125 3.11826 0.953125 2.49319V2.29902"
              stroke="white"
              stroke-width="0.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span className="text-[12px]">Mail schreiben</span>
        </a>
        <a
          href="#contact"
          className="flex flex-row items-center justify-center border-[0.5px] rounded-3xl border-flowWhite dark:text-flowWhite   py-4 px-6 gap-3 hover:bg-flowPrimary transition-all"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
          >
            <path
              d="M15.2001 12.7192C15.2001 12.9592 15.1467 13.2059 15.0334 13.4459C14.9201 13.6859 14.7734 13.9125 14.5801 14.1259C14.2534 14.4859 13.8934 14.7459 13.4867 14.9125C13.0867 15.0792 12.6534 15.1659 12.1867 15.1659C11.5067 15.1659 10.7801 15.0059 10.0134 14.6792C9.24672 14.3525 8.48005 13.9125 7.72005 13.3592C6.95339 12.7992 6.22672 12.1792 5.53339 11.4925C4.84672 10.7992 4.22672 10.0725 3.67339 9.31252C3.12672 8.55252 2.68672 7.79252 2.36672 7.03919C2.04672 6.27919 1.88672 5.55252 1.88672 4.85919C1.88672 4.40585 1.96672 3.97252 2.12672 3.57252C2.28672 3.16585 2.54005 2.79252 2.89339 2.45919C3.32005 2.03919 3.78672 1.83252 4.28005 1.83252C4.46672 1.83252 4.65339 1.87252 4.82005 1.95252C4.99339 2.03252 5.14672 2.15252 5.26672 2.32585L6.81339 4.50585C6.93339 4.67252 7.02005 4.82585 7.08005 4.97252C7.14005 5.11252 7.17339 5.25252 7.17339 5.37919C7.17339 5.53919 7.12672 5.69919 7.03339 5.85252C6.94672 6.00585 6.82005 6.16585 6.66005 6.32585L6.15338 6.85252C6.08005 6.92585 6.04672 7.01252 6.04672 7.11919C6.04672 7.17252 6.05339 7.21919 6.06672 7.27252C6.08672 7.32585 6.10672 7.36585 6.12005 7.40585C6.24005 7.62585 6.44672 7.91252 6.74005 8.25919C7.04005 8.60585 7.36005 8.95919 7.70672 9.31252C8.06672 9.66585 8.41338 9.99252 8.76672 10.2925C9.11338 10.5859 9.40005 10.7859 9.62672 10.9059C9.66005 10.9192 9.70005 10.9392 9.74672 10.9592C9.80005 10.9792 9.85338 10.9859 9.91338 10.9859C10.0267 10.9859 10.1134 10.9459 10.1867 10.8725L10.6934 10.3725C10.8601 10.2059 11.0201 10.0792 11.1734 9.99919C11.3267 9.90585 11.4801 9.85919 11.6467 9.85919C11.7734 9.85919 11.9067 9.88585 12.0534 9.94585C12.2001 10.0059 12.3534 10.0925 12.5201 10.2059L14.7267 11.7725C14.9001 11.8925 15.0201 12.0325 15.0934 12.1992C15.1601 12.3659 15.2001 12.5325 15.2001 12.7192Z"
              stroke="white"
              stroke-width="0.5"
              stroke-miterlimit="10"
            />
          </svg>

          <span className="text-[12px]">Anrufen</span>
        </a>
      </div>
    </footer>
  );
}

export default LandingPageFooter;
