import React, { useState, useRef } from "react";

const AdsAnalzyerOverview = () => {
  const [showBubble, setShowBubble] = useState(false);
  const [bubblePosition, setBubblePosition] = useState({ x: 0, y: 0 });
  const containerRef = useRef(null);

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setBubblePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
  };

  const handleMouseEnter = () => setShowBubble(true);
  const handleMouseLeave = () => setShowBubble(false);

  return (
    <div
      ref={containerRef}
      className="relative mx-auto lg:w-[1216px] lg:h-[400px] h-[180px] hover:cursor-none rounded-xl mt-5"
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <img
        src="./images/ads-analyzer-overview.png"
        alt="Ads Analyzer Overview Screenshots"
        className="w-full opacity-50 h-full lg:h-auto object-fill "
      />
      {showBubble && (
        <div
          className="absolute pointer-events-none transition-all duration-100 ease-out"
          style={{
            left: `${bubblePosition.x}px`,
            top: `${bubblePosition.y}px`,
            transform: "translate(-50%, -50%)",
          }}
        >
          <div className="relative w-[220px] h-[220px]">
            {/* Outer circle */}
            <div className="absolute inset-0 rounded-full border border-[0.5px] border-[rgba(181,_221,_255,_0.40);] opacity-30 animate-pulsate"></div>
            {/* Middle circle */}
            <div className="absolute inset-[15px] rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40);] opacity-60 animate-pulsate"></div>
            {/* Inner circle */}
            <div className="absolute inset-[30px] rounded-full border-[0.5px] border-[rgba(181,_221,_255,_0.40);] opacity-90 animate-pulsate"></div>
            {/* Center white circle with content */}
            <div className="absolute inset-[30px] bg-white rounded-full shadow-lg flex flex-row justify-center items-center w-40 h-40">
            <div className="flex flex-row justify-center items-center gap-2">
              <p className="text-[#1A1B1D] text-sm mt-2 leading-none">Entdecken</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="4"
                viewBox="0 0 13 4"
                fill="none"
              >
                <path
                  d="M12.6768 2.17678C12.7744 2.07915 12.7744 1.92085 12.6768 1.82322L11.0858 0.232233C10.9882 0.134602 10.8299 0.134602 10.7322 0.232233C10.6346 0.329864 10.6346 0.488155 10.7322 0.585786L12.1464 2L10.7322 3.41421C10.6346 3.51184 10.6346 3.67014 10.7322 3.76777C10.8299 3.8654 10.9882 3.8654 11.0858 3.76777L12.6768 2.17678ZM0.5 2.25H12.5V1.75H0.5V2.25Z"
                  fill="#1A1B1D"
                />
              </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdsAnalzyerOverview;
