import React, { useState, useEffect, useCallback } from "react";
import AnalysisDashboard from "./components/AnalysisDashboard";
import AccountDropdown from "./components/AccountDropdown";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import "../../App.css";
import NavBar from "./components/NavBar";
import { jwtDecode } from "jwt-decode";
import { CircleLoader } from "react-spinners";
import {
  SkeletonKPI,
  SkeletonGraph,
  SkeletonInsight,
} from "./refactor/SkeletonLoarder";
import LandingPageFooter from "../landingpage/LandingPageFooter";

const SERVER_URL = "https://flow-ads-server-e2f2024fb49d.herokuapp.com";

function MainAnalyzer() {
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [accounts, setAccounts] = useState(() => {
    const cachedAccounts = localStorage.getItem("cachedAccounts");
    return cachedAccounts ? JSON.parse(cachedAccounts) : [];
  });
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [dashboardData, setDashboardData] = useState(null);
  const [analysisData, setAnalysisData] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const isShowingLoader = isLoading || isAnalyzing;
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(false);
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [analysisJobId, setAnalysisJobId] = useState(null);
  const [analysisStatus, setAnalysisStatus] = useState("not_started");
  const [analysisError, setAnalysisError] = useState(null);
  const [accountDataStatus, setAccountDataStatus] = useState("not_started");
  const [error, setError] = useState(null);

  const handleLogin = (credentialResponse) => {
    const token = credentialResponse.credential;
    setToken(token);
    localStorage.setItem("token", token);
    const decodedToken = jwtDecode(token);
    const newUserInfo = {
      name: decodedToken.name,
      firstName: decodedToken.given_name,
      lastName: decodedToken.family_name,
      picture: decodedToken.picture,
      email: decodedToken.email,
    };
    setUserInfo(newUserInfo);
    localStorage.setItem("userInfo", JSON.stringify(newUserInfo));
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setToken(null);
    setIsLoggedIn(false);
    setUserInfo(null);
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("cachedAccounts");
    setAccounts([]);
    setSelectedAccount(null);
    setDashboardData(null);
    setAnalysisData(null);
    googleLogout();
  };

  const handleUnauthorized = () => {
    console.log("Unauthorized access detected. Logging out...");
    handleLogout();
  };

  const fetchWithAuth = async (url, options = {}) => {
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        "Content-Type": "application/json",
        token: token,
      },
    });

    if (response.status === 401) {
      handleUnauthorized();
      throw new Error("Unauthorized");
    }

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  };

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
    document.documentElement.classList.toggle("dark");
  };

  const onLinkAdsAccount = () => {
    window.location.href = `${SERVER_URL}/authorize?token=${token}`;
  };

  const fetchAccounts = useCallback(async () => {
    if (!token) return;
    if (accounts.length > 0) return; // Don't fetch if we already have accounts

    setIsLoading(true);
    setIsLoadingDashboard(true);
    try {
      const data = await fetchWithAuth(`${SERVER_URL}/list_accounts`);
      const accountHierarchy = buildAccountHierarchy(data);
      setAccounts(accountHierarchy);
      localStorage.setItem("cachedAccounts", JSON.stringify(accountHierarchy));
      if (accountHierarchy.length > 0 && !selectedAccount) {
        setSelectedAccount(accountHierarchy[0]);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingDashboard(false);
    }
  }, [token, accounts.length, selectedAccount]);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUserInfo = localStorage.getItem("userInfo");

    if (storedToken && !token) {
      setToken(storedToken);
      setIsLoggedIn(true);
    }

    if (storedUserInfo && !userInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }
  }, []); // This effect runs only once on component mount

  useEffect(() => {
    if (token && accounts.length === 0) {
      fetchAccounts();
    }
  }, [token, accounts.length, fetchAccounts]);

  const buildAccountHierarchy = (flatAccounts) => {
    const accountMap = new Map();
    const rootAccounts = [];

    flatAccounts.forEach((account) => {
      account.child_accounts = [];
      accountMap.set(account.customer_id, account);
    });

    flatAccounts.forEach((account) => {
      if (account.manager) {
        rootAccounts.push(account);
      } else if (account.manager_id) {
        const parent = accountMap.get(account.manager_id);
        if (parent) {
          parent.child_accounts.push(account);
        } else {
          rootAccounts.push(account);
        }
      } else {
        rootAccounts.push(account);
      }
    });

    return rootAccounts;
  };

  const findAccount = (accounts, accountId) => {
    for (let account of accounts) {
      if (account.customer_id === accountId) {
        return account;
      }
      if (account.child_accounts) {
        const childAccount = findAccount(account.child_accounts, accountId);
        if (childAccount) {
          return childAccount;
        }
      }
    }
    return null;
  };

  const findTopLevelManager = (acc) => {
    console.log("Finding top level manager for:", acc);
    if (acc.manager) return acc.customer_id;
    if (!acc.manager_id) return acc.customer_id;
    const parentAccount = findAccount(accounts, acc.manager_id);
    return parentAccount ? findTopLevelManager(parentAccount) : acc.customer_id;
  };

  const pollAccountDataStatus = useCallback(
    async (customerId) => {
      const maxRetries = 10;
      const pollInterval = 5000; // 5 seconds

      for (let i = 0; i < maxRetries; i++) {
        try {
          const response = await fetchWithAuth(
            `${SERVER_URL}/check_account_data_status/${customerId}`
          );
          console.log("Account data status:", response);
          if (response.status === "completed") {
            const formattedData = {
              dashboardData: response.data,
            };
            setDashboardData(formattedData);
            console.log("Setting dashboard data:", formattedData);
            setAccountDataStatus("completed");
            return formattedData;
          }
          // If still in progress, wait before next poll
          await new Promise((resolve) => setTimeout(resolve, pollInterval));
        } catch (error) {
          console.error("Error checking account data status:", error);
          // Continue polling on error
        }
      }
      setError("Timeout while fetching account data. Please try again.");
      return null;
    },
    [fetchWithAuth]
  );

  const pollAnalysisStatus = useCallback(
    async (jobId) => {
      let retries = 0;
      const maxRetries = 10;
      const pollInterval = 5000; // 5 seconds

      const poll = async () => {
        try {
          const response = await fetchWithAuth(
            `${SERVER_URL}/check_analysis_status/${jobId}`
          );
          console.log("Analysis status:", response);

          if (response.status === "completed") {
            setAnalysisStatus("completed");
            if (response.result) {
              console.log("Analysis completed", response.result);
              // Only set analysisData, not dashboardData
              setAnalysisData(response.result);
              console.log("Setting analysis data:", response.result);
              return response.result;
            } else {
              console.error("Analysis completed but no data received");
              setError(
                "Analysis completed but no data received. Please try again."
              );
              return null;
            }
          } else if (response.status === "failed") {
            setAnalysisStatus("failed");
            setError("Analysis failed. Please try again.");
            return null;
          } else if (response.status === "in_progress") {
            console.log("Analysis still in progress...");
            return false; // Continue polling
          } else {
            console.error("Unexpected response status", response.status);
            setError(`Unexpected analysis status: ${response.status}`);
            return null;
          }
        } catch (error) {
          console.error("Error checking analysis status:", error);
          if (retries < maxRetries) {
            retries++;
            console.log(`Retrying... Attempt ${retries} of ${maxRetries}`);
            return false; // Continue polling
          } else {
            setAnalysisStatus("failed");
            setError(`Error checking analysis status: ${error.message}`);
            return null;
          }
        }
      };

      while (true) {
        const result = await poll();
        if (result === false) {
          await new Promise((resolve) => setTimeout(resolve, pollInterval));
        } else {
          return result;
        }
      }
    },
    [fetchWithAuth, SERVER_URL]
  );

  const analyzeAccount = useCallback(
    async (account) => {
      console.log("analyzeAccount started", { account });
      try {
        const loginCustomerId = findTopLevelManager(account);
        const response = await fetchWithAuth(`${SERVER_URL}/analyze_data`, {
          method: "POST",
          headers: {
            "login-customer-id": loginCustomerId,
          },
          body: JSON.stringify({ customer_id: account.customer_id }),
        });
        console.log("analyzeAccount response", { response });

        if (response.status === "completed" && response.result) {
          console.log("Analysis completed", response.result);
          setAnalysisData(response.result);
          setAnalysisStatus("completed");
          return response.result;
        } else if (response.status === "in_progress") {
          console.log("Analysis in progress, starting polling");
          const pollingResult = await pollAnalysisStatus(response.job_id);
          if (pollingResult) {
            console.log("Analysis completed after polling", pollingResult);
            return pollingResult;
          } else {
            throw new Error("Failed to retrieve analysis result after polling");
          }
        } else {
          console.error("Unexpected response status", response.status);
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error in analyzeAccount:", error);
        setAnalysisError(error.message);
        setAnalysisStatus("failed");
        throw error;
      }
    },
    [findTopLevelManager, fetchWithAuth, pollAnalysisStatus]
  );
  const fetchAccountData = useCallback(
    async (account) => {
      console.log("fetchAccountData started", { account });
      try {
        const loginCustomerId = findTopLevelManager(account);
        const response = await fetchWithAuth(
          `${SERVER_URL}/account_data?customer_id=${account.customer_id}`,
          {
            headers: {
              "login-customer-id": loginCustomerId,
            },
          }
        );
        console.log("fetchAccountData response", { response });

        if (response.status === "in_progress") {
          console.log("Account data fetch in progress, starting polling");
          const dataReady = await pollAccountDataStatus(account.customer_id);
          if (dataReady) {
            console.log("Account data ready", dataReady);
            return dataReady;
          } else {
            throw new Error("Failed to retrieve account data after polling");
          }
        } else if (response.status === "completed") {
          console.log("Account data fetch completed", response.data);
          return response.data;
        } else {
          console.error("Unexpected response status", response.status);
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error in fetchAccountData:", error);
        throw error; // Re-throw the error to be handled by the caller
      }
    },
    [findTopLevelManager, fetchWithAuth, pollAccountDataStatus]
  );

  useEffect(() => {
    if (analysisJobId && analysisStatus === "in_progress") {
      const cancelPoll = pollAnalysisStatus(analysisJobId);
      return cancelPoll;
    }
  }, [analysisJobId, analysisStatus, pollAnalysisStatus]);

  const handleAccountChange = useCallback(
    async (event) => {
      console.log("handleAccountChange started", { event });
      const accountId = event.target.value;
      const account = findAccount(accounts, accountId);
      setSelectedAccount(account);
      if (account) {
        let accountData = null;
        let analysisResult = null;
        try {
          setIsLoading(true);
          setIsLoadingDashboard(true);
          setIsLoadingAnalysis(true);
          setError(null);
          setAnalysisError(null);
          setDashboardData(null);
          setAnalysisData(null);
          setAnalysisStatus("not_started");
          setAccountDataStatus("not_started");

          console.log("Starting fetchAccountData");
          accountData = await fetchAccountData(account);
          console.log("fetchAccountData completed", accountData);
          setDashboardData(accountData);
          setAccountDataStatus("completed");

          console.log("Starting analyzeAccount");
          analysisResult = await analyzeAccount(account);
          console.log("analyzeAccount completed", analysisResult);
          setAnalysisData(analysisResult);
          setAnalysisStatus("completed");
        } catch (error) {
          console.error("Error in handleAccountChange:", error);
          setError("Failed to process account change. Please try again.");
          setAnalysisStatus("failed");
          setAccountDataStatus("error");
        } finally {
          setIsLoading(false);
          setIsLoadingDashboard(false);
          setIsLoadingAnalysis(false);
          console.log("handleAccountChange finished", {
            selectedAccount: account,
            isLoading: false,
            isLoadingDashboard: false,
            isLoadingAnalysis: false,
            hasDashboardData: !!accountData,
            hasAnalysisData: !!analysisResult,
            analysisStatus: analysisStatus,
            accountDataStatus: accountDataStatus,
          });
        }
      }
    },
    [accounts, fetchAccountData, analyzeAccount]
  );

  useEffect(() => {
    console.log("State updated", {
      selectedAccount,
      isLoading,
      isLoadingDashboard,
      isLoadingAnalysis,
      analysisStatus,
      hasAnalysisData: !!analysisData,
      hasDashboardData: !!dashboardData,
    });
  }, [
    selectedAccount,
    isLoading,
    isLoadingDashboard,
    isLoadingAnalysis,
    analysisStatus,
    analysisData,
    dashboardData,
  ]);

  return (
    <div className="lg:px-20 px-4 bg-white dark:bg-flowDark">
      <NavBar
        accounts={accounts}
        selectedAccount={selectedAccount}
        onAccountChange={handleAccountChange}
        isDarkMode={isDarkMode}
        toggleDarkMode={toggleDarkMode}
        userInfo={userInfo}
        onLogout={handleLogout}
      />
      {isShowingLoader && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <CircleLoader loading={isShowingLoader} color="#00b7ff" size={100} />
        </div>
      )}
      <div className="min-h-screen py-6 flex flex-col items-center">
        <div className="w-full">
          <GoogleLogin
            onSuccess={handleLogin}
            onError={() => console.log("Login Failed")}
            className="w-full"
          />

          <div className="">
            {(isLoadingDashboard ||
              isLoadingAnalysis ||
              analysisStatus === "in_progress") && (
              <>
                <div className="text-center mt-4">
                  <p className="text-lg font-semibold">
                    {isLoadingDashboard
                      ? "Loading dashboard..."
                      : isLoadingAnalysis
                      ? "Loading analysis..."
                      : "Analysis in progress..."}
                  </p>
                  <CircleLoader loading={true} color="#00b7ff" size={50} />
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-6">
                  {[...Array(6)].map((_, index) => (
                    <SkeletonKPI key={index} />
                  ))}
                </div>
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                  <SkeletonGraph />
                  <SkeletonGraph />
                </div>
                <div className="w-full mb-6">
                  <SkeletonInsight />
                </div>
              </>
            )}
            {!isLoadingDashboard &&
              !isLoadingAnalysis &&
              analysisStatus === "completed" &&
              analysisData &&
              dashboardData && (
                <AnalysisDashboard
                  analysisData={analysisData}
                  dashboardData={dashboardData}
                  isLoadingDashboard={isLoadingDashboard}
                  isLoadingAnalysis={isLoadingAnalysis}
                />
              )}
            {analysisError && (
              <div className="text-center mt-4 text-red-500">
                <p>{analysisError}</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <LandingPageFooter />
    </div>
  );
}

export default MainAnalyzer;
