import React from "react";
import HubspotContactForm from "./LandingPageContactForm";
import HubspotForm from "react-hubspot-form";
const flowPeople = [
  {
    name: "Oskar Bösener",
    role: "Chief Executive Officer",
    image: "https://via.placeholder.com/250",
  },
  {
    name: "Anna Uhlig",
    role: "Chief Executive Officer",
    image: "https://via.placeholder.com/250",
  },
];
function LandingPageContact() {
  return (
    <section
      className="lg:py-28 py-10 lg:max-w-[1216px] lg:mx-auto flex lg:flex-row flex-col px-4 justify-between items-start gap-10"
      id="contact"
    >
      <div className="flex flex-col items-start justify-start gap-10">
        <h2 className="text-4xl lg:text-flow-48 font-light leading-snug">
          <span className="text-flowPrimary">Wie sind hier, um zuzuhören.</span>
          <br />
          Kontaktiere uns jederzeit gerne.{" "}
        </h2>
        <div className="grid lg:grid-cols-2 grid-cols-1 w-full lg:w-auto lg:justify-items-start justify-items-center gap-8">
          {flowPeople.map((person, index) => (
            <div className="flex flex-col items-center justify-center lg:max-w-56">
              <img
                src={person.image}
                alt={person.name}
                className="rounded-full lg:size-40 mb-5"
              />
              <h3 className="text-white leading-snug mb-2">{person.name}</h3>
              <p className="text-sm font-light text-[#C1C1C1]">{person.role}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center w-full">
        <HubspotForm
          region="eu1"
          portalId="143815556"
          formId="9ada09f6-7f1e-4e2b-8bd9-799b2182470b"
          target="#hubspotForm"
        />
      </div>
    </section>
  );
}

export default LandingPageContact;
