import React from "react";
import GoogleLogo from "./GoogleLogo";
import AdsAnalzyerOverview from "./AdsAnalzyerOverview";

const InfoandTeaser = () => {
    return (
        <section className="flex flex-col justify-center items-center gap-8  px-4">
            <p className="flowGradientText text-lg text-center">FLOW Innovations - dein Partner für nachhaltigen Werbeerfolg</p>

            <h2 className="text-[#F9F8F7] text-center text-2xl leading-normal tracking-wider lg:max-w-[1081px] font-light">
            Kämpfst du mit <b className="font-medium">werblichen Zweifeln</b> und <b className="font-medium">unklarer Budgetrendite?</b> <br></br>
            Unser kostenloses Google Ads Audit hilft! Finde Klarheit, entdecke Potenziale und maximiere Erfolge - mit maßgeschneiderten Lösungen.
            </h2>

            <div className="w-[155px]">
            <GoogleLogo width="155px" height="auto" />
            </div>
            <AdsAnalzyerOverview />

        </section>
    )
}

export default InfoandTeaser;