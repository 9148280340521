import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const services = [
  {
    title: 'Strategische Planung & Entwicklung "No Brainer Offer"',
    description:
      "Lörem ipsum didade dekälingar dir ben. Triskade trenas och sufårat att egoras innan autovis. Diar krortad krosm i viferade ett kros. Faling erat gir, prerade. Spegelig jes. Testik bir, lagiligt och dialing. Stenosm negt. Sess heteromani doniskade aligt. Hexavis syvävis jel. Antidysade viling: risa stadsutglesning. Obelt nir, och smygöppna. Mirad pode gigasm. ",
  },
  {
    title: "Aufsetzen des Tracking nach Google Vorgabe",
    description:
      "Lörem ipsum didade dekälingar dir ben. Triskade trenas och sufårat att egoras innan autovis. Diar krortad krosm i viferade ett kros. Faling erat gir, prerade. Spegelig jes. Testik bir, lagiligt och dialing. Stenosm negt. Sess heteromani doniskade aligt. Hexavis syvävis jel. Antidysade viling: risa stadsutglesning. Obelt nir, och smygöppna. Mirad pode gigasm. ",
  },
  {
    title: "Expertise in allen Google Formaten (erstellen & schalten)",
    description:
      "Lörem ipsum didade dekälingar dir ben. Triskade trenas och sufårat att egoras innan autovis. Diar krortad krosm i viferade ett kros. Faling erat gir, prerade. Spegelig jes. Testik bir, lagiligt och dialing. Stenosm negt. Sess heteromani doniskade aligt. Hexavis syvävis jel. Antidysade viling: risa stadsutglesning. Obelt nir, och smygöppna. Mirad pode gigasm. ",
  },
  {
    title: "Wöchentliches & monatliches Reporting",
    description:
      "Wir halten dich wöchentlich über unsere Google Ads-Kampagnen auf dem Laufenden. Verfolge wichtige Kennzahlen, analysiere Leistungstrends und optimiere deine Werbestrategie, um den ROI zu maximieren. Mit unseren monatlichen Reportings erhältst du tiefere Einblicke in die Leistung deiner Google Ads. Dadurch unterstützen wir dich, Verbesserungsmöglichkeiten zu identifizieren, deine Zielgruppenauswahl zu verfeinern und deine Anzeigenkreationen für bessere Ergebnisse zu optimieren.",
  },
];

const FaqAccordion = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    return (
      <div className="max-w-[1216px] mx-auto py-16 px-4 flex flex-col gap-14" id="faq ">
        <h2 className="text-4xl font-light mb-12 text-flowWhite w-full lg:text-right text-center">
          Hast du noch <span className="text-flowPrimary">Fragen?</span>
        </h2>

        <div className="space-y-8">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="pb-8 relative"
              initial={false}
            >
              <motion.button
                className="w-full flex justify-between items-center cursor-pointer text-left"
                onClick={() => setExpandedIndex(expandedIndex === index ? null : index)}
                aria-expanded={expandedIndex === index}
              >
                <h3 className="text-2xl text-white">{service.title}</h3>
                <motion.span
                  className="text-3xl text-flowWhite"
                  animate={{ rotate: expandedIndex === index ? 45 : 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M9.00091 17.4853L9.00091 0.514719M0.515625 9H17.4862"
                      stroke="white"
                      strokeWidth="0.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </motion.span>
              </motion.button>

              <AnimatePresence initial={false}>
                {expandedIndex === index && (
                  <>
                    <motion.div
                      key="content"
                      initial="collapsed"
                      animate="open"
                      exit="collapsed"
                      variants={{
                        open: { opacity: 1, height: "auto", marginTop: "1rem" },
                        collapsed: { opacity: 0, height: 0, marginTop: 0 },
                      }}
                      transition={{ duration: 0.3, ease: "easeInOut" }}
                      className="overflow-hidden"
                    >
                      <p className="text-[#F9F8F7] text-flow-20 font-light tracking-wide leading-snug">
                        {service.description}
                      </p>
                    </motion.div>
                    <motion.div
                      key="border"
                      className="absolute bottom-0 left-0 h-[1px] bg-flowPrimary"
                      initial={{ width: 0 }}
                      animate={{ width: "100%" }}
                      transition={{ duration: 0.5, ease: "easeInOut" }}
                    />
                  </>
                )}
              </AnimatePresence>
              {expandedIndex !== index && (
                <div className="absolute bottom-0 left-0 w-full h-[1px] bg-[#333]" />
              )}
            </motion.div>
          ))}
        </div>
      </div>
    );
  };

  export default FaqAccordion;