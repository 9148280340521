import React from "react";

const FlowLogo = ({ width = "100%", height = "auto" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 2274.92 548.4"
      width={width}
      height={height}
    >
      <defs>
        <linearGradient id="d" x1="0" y1="205.97" x2="2274.92" y2="205.97" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#288aef"/>
          <stop offset=".38" stopColor="#47a9e8"/>
          <stop offset=".77" stopColor="#62c4e3"/>
          <stop offset="1" stopColor="#6dcfe2"/>
        </linearGradient>
        <linearGradient id="e" x1="72.12" y1="344.53" x2="2038.87" y2="344.53" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="#006ac1"/>
          <stop offset=".11" stopColor="#0773c7"/>
          <stop offset=".3" stopColor="#1d8ed7"/>
          <stop offset=".54" stopColor="#3fb8f2"/>
          <stop offset=".59" stopColor="#48c3f9"/>
          <stop offset=".61" stopColor="#4fc5f6"/>
          <stop offset=".67" stopColor="#6bcdef"/>
          <stop offset=".73" stopColor="#80d3e9"/>
          <stop offset=".8" stopColor="#90d8e5"/>
          <stop offset=".88" stopColor="#99dbe2"/>
          <stop offset="1" stopColor="#9cdce2"/>
        </linearGradient>
      </defs>
      <g id="b">
        <g id="c">
          <g>
            <path className="g" fill="url(#d)" d="M1300.29,84.53c111.39,112.76,89.95,238.1,220.52,318.93,0,0,64.51,35.75,97.93-39.64s24.26-129.9,251.31-127.89c227.05,2.01,404.87-10.05,404.87-10.05,0,0-70.32-24.11-325.5-33.15-255.18-9.04-310.43,17.08-354.64,117.54-44.2,100.46-95.44,24.11-115.53-8.04-20.09-32.15-65.88-123.05-65.88-123.05,0,0-58.15-124.5-139.24-163.81-81.09-39.31-171.18,.82-258.82,74.53-7.44,6.26-14.68,12.26-21.76,18.03-118.22,96.31-263.26,153.71-415.38,164.26-1.28,.09-2.58,.18-3.87,.26C445.28,281.02,0,249.61,0,249.61c0,0,458.79,84.11,697.74,44.47,2.55-.42,5.08-.85,7.6-1.3,124.5-21.79,241.3-75.29,340.15-154.06l16.68-13.29c10.13-8.08,20.04-16.42,29.77-24.97,29.76-26.15,126.69-98.59,208.34-15.93Z"/>
            <path className="f" fill="url(#e)" d="M414.11,259.66c61.18-15.65,157.92-124.48,310.86-118.8,152.93,5.69,219.87,84.56,353.43,215.34,133.56,130.77,264.74,169.05,368.41,15.15,103.66-153.9,256.24-161.17,256.24-161.17,156.16-19.52,335.81,25.28,335.81,25.28,0,0-83.47-1.74-257.04,4.44-65.29,2.32-117.47,20.29-157.16,41.82-44.48,24.12-82.01,59.24-110.03,101.37-27.76,41.75-98.23,135.57-184.6,159.26-112.06,30.74-220.98-58.54-344.2-198.3-123.22-139.75-200.96-159.6-276.17-160.53-75.21-.92-165.77,44.32-254.3,82.5-88.53,38.17-383.26-8.97-383.26-8.97,0,0,214.68,36.04,341.99,2.61Z"/>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FlowLogo;