import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";

const numbers = [
    {
        number: "€155Mio",
        description: "Zusammen mit dem Fitnesszubehör Anbieter in Deutschland haben wir das Google Ads Konto inkl."
    },
    {
        number: "€52Mio",
        description: "Zusammen mit dem Fitnesszubehör Anbieter in Deutschland haben wir das Google Ads Konto inkl."
    },
    {
        number: "> 150",
        description: "Zusammen mit dem Fitnesszubehör Anbieter in Deutschland haben wir das Google Ads Konto inkl."
    }
];

const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
        opacity: 1,
        transition: {
            staggerChildren: 0.2
        }
    }
};

const itemVariants = {
    hidden: { x: -50, opacity: 0 },
    visible: {
        x: 0,
        opacity: 1,
        transition: {
            type: "spring",
            stiffness: 60,
            damping: 12
        }
    }
};

const LandingPageNumbers = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.3 });

    return (
        <section ref={ref} className="lg:max-w-[1216px] lg:mx-auto flex flex-col  gap-16 justify-start items-start pb-28 p-4">
            <motion.h3
                className="text-flowPrimary tracking-wider text-center lg:text-left w-full"
                variants={itemVariants}
                initial="hidden"
                animate={isInView ? "visible" : "hidden"}
            >
                Our numbers speak for itself
            </motion.h3>
            <div className="relative w-full lg:border-none border-l border-l-flowPrimary pl-6 lg:pl-0 ">
                <div className="hidden lg:block w-full h-[2px] rounded-3xl absolute top-1/2 transform -translate-y-1/2" style={{background: 'radial-gradient(210.77% 113.26% at 5.95% 7.14%, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(136deg, rgba(255, 255, 255, 0.23) 0%, rgba(255, 255, 255, 0.00) 97.5%), #34B2FF'}}></div>
                <motion.div
                    className="grid lg:grid-cols-3 grid-cols-1 gap-20 justify-between"
                    variants={containerVariants}
                    initial="hidden"
                    animate={isInView ? "visible" : "hidden"}
                >
                    {numbers.map((number, index) => (
                        <motion.div key={index} className="flex flex-col lg:gap-20 gap-6" variants={itemVariants}>
                            <motion.h2 className="lg:text-5xl text-[40px] font-light text-flowWhite" variants={itemVariants}>
                                {number.number}
                            </motion.h2>
                            <motion.p className="text-[#F9F8F7] font-light leading-snug tracking-wider" variants={itemVariants}>
                                {number.description}
                            </motion.p>
                        </motion.div>
                    ))}
                </motion.div>
            </div>
        </section>
    );
}

export default LandingPageNumbers;