import React from 'react';

const UserInfo = ({ userInfo, onLogout }) => {
  if (!userInfo) return null;

  return (
    <div className="flex items-center space-x-4">
      <img
        src={userInfo.picture}
        alt={userInfo.name}
        className="w-10 h-10 rounded-full"
      />
      <div>
        <span className="text-sm font-medium text-[#333] dark:text-white">
          {userInfo.name}
        </span>
        <p className="text-xs text-gray-500 dark:text-gray-400">
          {userInfo.email}
        </p>
      </div>
      <button
        onClick={onLogout}
        className="text-sm text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
      >
        Logout
      </button>
    </div>
  );
};

export default UserInfo;