import React from 'react';

const SkeletonKPI = () => (
  <div className="p-6 rounded-2xl dark:border dark:border-[#5E5E5E] dashboardShadow animate-pulse">
    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-3/4 mb-4"></div>
    <div className="h-8 bg-gray-200 dark:bg-gray-700 rounded w-1/2 mb-2"></div>
    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full"></div>
  </div>
);

const SkeletonGraph = () => (
  <div className="h-64 rounded-2xl dark:border dark:border-[#5E5E5E] dashboardShadow animate-pulse">
    <div className="h-full bg-gray-200 dark:bg-gray-700 rounded"></div>
  </div>
);

const SkeletonInsight = () => (
  <div className="p-6 rounded-2xl dark:border dark:border-[#5E5E5E] dashboardShadow animate-pulse">
    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-1/4 mb-4"></div>
    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-full mb-2"></div>
    <div className="h-4 bg-gray-200 dark:bg-gray-700 rounded w-5/6"></div>
  </div>
);

export { SkeletonKPI, SkeletonGraph, SkeletonInsight };